var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("header", { staticClass: "pb-6 sm:pb-8 flex flex-row items-end" }, [
    _c("div", { staticClass: "flex w-full" }, [
      _c(
        "div",
        {
          staticClass:
            "flex w-full justify-start font-bold leading-tight text-lg text-left text-orange-500 text-xl mb-0 flex-wrap justify-between items-center",
        },
        [
          _c(
            "h1",
            { staticClass: "flex flex-row items-center" },
            [
              _vm._t("icon"),
              _vm._t("title"),
              _vm.isLoading
                ? _c(
                    "span",
                    { staticClass: "flex items-center ml-6" },
                    [_c("sun-loading", { staticClass: "h-6 text-orange-600" })],
                    1
                  )
                : _vm._e(),
            ],
            2
          ),
          _vm._t("right"),
          null != _vm.routerLink && _vm.showCreateButton
            ? _c(
                "div",
                { staticClass: "ml-4 flex justify-end items-center mt-1" },
                [
                  _c(
                    "router-link",
                    { attrs: { to: { name: _vm.routerLink } } },
                    [
                      _c(
                        "sun-button",
                        {
                          staticClass:
                            "px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800",
                          attrs: { variant: "pill", color: "gray" },
                        },
                        [_vm._v(" " + _vm._s(_vm.newButtonLabel) + " ")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
    ]),
    _vm.showSync
      ? _c(
          "div",
          { staticClass: "ml-4" },
          [
            _c(
              "sun-button",
              {
                staticClass: "custom-p-1",
                attrs: { color: "orange", variant: "pill" },
                on: {
                  click: function ($event) {
                    return _vm.sync()
                  },
                },
              },
              [
                _c(
                  "div",
                  { staticClass: "flex items-center text-xs h-4" },
                  [
                    _c("SyncSvg", {
                      staticClass: "w-3 md:mr-1 transition-1000",
                      class: { "rotate-360": _vm.isSynchronizing },
                    }),
                    _c("span", { staticClass: "hidden md:flex text-xs" }, [
                      _vm._v("Sync"),
                    ]),
                  ],
                  1
                ),
              ]
            ),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }