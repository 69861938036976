<template>
  <header class="pb-6 sm:pb-8 flex flex-row items-end">
    <div class="flex w-full">
      <div
        class="flex w-full justify-start font-bold leading-tight text-lg text-left text-orange-500 text-xl mb-0 flex-wrap justify-between items-center"
      >
        <h1 class="flex flex-row items-center">
          <slot name="icon" class="w-8 mr-5" />
          <slot name="title" />
          <span v-if="isLoading" class="flex items-center ml-6">
            <sun-loading class="h-6 text-orange-600" />
          </span>
        </h1>
        <slot name="right" />
        <div v-if="null != routerLink && showCreateButton" class="ml-4 flex justify-end items-center mt-1">
          <router-link :to="{ name: routerLink }">
            <sun-button variant="pill" class="px-4 custom-p-1 text-xs bg-gray-700 hover:bg-gray-800" color="gray">
              {{ newButtonLabel }}
            </sun-button>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="showSync" class="ml-4">
      <sun-button color="orange" variant="pill" class="custom-p-1" @click="sync()">
        <div class="flex items-center text-xs h-4">
          <SyncSvg class="w-3 md:mr-1 transition-1000" :class="{ 'rotate-360': isSynchronizing }" />
          <span class="hidden md:flex text-xs">Sync</span>
        </div>
      </sun-button>
    </div>
  </header>
</template>

<script>
export default {
  name: 'SectionHeader',
  components: {
    SyncSvg: () => import('../icons/SyncSvg'),
  },
  props: {
    routerLink: {
      type: [String, Object],
      default: null,
    },
    newButtonLabel: {
      type: String,
      default: '+ New Resource',
    },
    isLoading: {
      type: Boolean,
      default: () => false,
    },
    showSync: {
      type: Boolean,
      default: () => false,
    },
    showCreateButton: {
      type: Boolean,
      default: () => false,
    },
  },
  data() {
    return {
      isSynchronizing: false,
    };
  },
  methods: {
    sync() {
      this.isSynchronizing = true;
      this.$emit('sync');
      setTimeout(() => (this.isSynchronizing = false), 1000);
    },
  },
};
</script>
